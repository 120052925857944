<template>
  <el-dialog v-if="showDialog" :title="$t('createReading.title')" v-model="showDialog" :close-on-click-modal="false">
    <el-form label-width="140px">
      <el-form-item :label="$t('createReading.formLabels.meter')">
        <el-select v-model="reading.meter" placeholder="Seleziona" prop="meter">
          <el-option
              v-for="meter in meterList"
              :key="meter.id"
              :label="meter.serialNumber"
              :value="meter.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('createReading.formLabels.dateTime')">
        <el-date-picker
            v-model="reading.dateTime"
            type="date"
            placeholder=""
            format="DD/MM/YYYY"
            value-format="DD/MM/YYYY"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item :label="$t('createReading.formLabels.value')">
        <el-input-number v-model="reading.value" :controls="false" />
      </el-form-item>
      <el-form-item :label="$t('createReading.formLabels.error')">
        <el-input v-model="reading.error"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close"> {{$t('buttons.cancel')}}  </el-button>
        <el-button type="primary" @click="submit"> {{$t('buttons.upload')}} </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import {mapActions} from "vuex";

export default {
  name: "CreateReadingDialog",
  data() {
    return {
      showDialog: false,
      meterList: [],
      reading:{
        meter: "",
        dateTime: "",
        value: 0,
        error: ""
      },
    }
  },
  computed: {
    ...mapGetters(["currentApartment"])
  },
  methods: {
    ...mapActions(["createReading"]),
    show(){
      this.showDialog = true;
    },
    close(){
      this.showDialog = false;
    },
    async submit(){
      console.log(this.reading)
      if (this.reading.meter===""){
        console.log("meter void")
      } else if (this.reading.dateTime===""){
        console.log("date void")
      } else {
        await this.createReading(this.reading)
        this.$message.success('Dati caricati correttamente');
        this.close();
      }
    }
  },
  created() {
    if (this.currentApartment){
      this.meterList = this.currentApartment.meters;
    }
    console.log(this.meterList)
  }
}
</script>

<style scoped>

</style>
