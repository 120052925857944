<template>
  <div>
    <h3 v-if="currentApartment">{{ $t("readings.title", {name: getFullName(currentApartment.user)}) }}</h3>
    <el-card class="box-card" v-if="isGranted('ROLE_MANAGER')">
      <el-button type="primary" icon="el-icon-plus" @click="openCreateReadingDialog">Aggiungi Lettura</el-button>
    </el-card>
    <el-card class="box-card">
      <strong>Inizio Stagione</strong>
      <el-select v-model="selectedYear" @change="yearChanged" class="ml-10">
        <el-option
            v-for="item in years"
            :key="item"
            :label="stringifyReadingDate(currentApartment.condominium.readingsStartDate)+' '+item"
            :value="item">
        </el-option>
      </el-select>
      <el-button-group class="ml-10">
        <el-button :type="isSelected('list')" @click="select('list')" icon="el-icon-tickets">Lista</el-button>
        <el-button :type="isSelected('chart')" @click="select('chart')" icon="el-icon-data-line">Grafico</el-button>
      </el-button-group>
      <el-button class="ml-10" @click="compare" :type="compareMode?'primary':''">
        Confronto
      </el-button>
    </el-card>
    <el-card class="box-card">
      <apartment-readings-list v-show="viewMode==='list'" :selected-year="selectedYear" :compareMode="compareMode"></apartment-readings-list>
      <apartment-readings-chart v-show="viewMode==='chart'" ref="readingsChart"
                                :key="updater" :compareMode="compareMode"></apartment-readings-chart>
    </el-card>
    <create-reading-dialog ref="createReadingDialog" v-if="isGranted('ROLE_MANAGER')"></create-reading-dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ApartmentReadingsList from "../../components/Readings/ApartmentReadingsList";
import ApartmentReadingsChart from "../../components/Readings/ApartmentReadingsChart";
import DateMixin from "../../mixins/DateMixin";
import UserMixin from "../../mixins/UserMixin";
import CreateReadingDialog from "../../components/CreateReadingDialog";

export default {
  name: "Readings",
  components: {CreateReadingDialog, ApartmentReadingsList, ApartmentReadingsChart},
  mixins: [DateMixin, UserMixin],
  data() {
    return {
      selectedYear: 0,
      viewMode: "list",
      datasets: {},
      datasets2: {},
      updater: 0,
      compareMode: false,
      years: [],
    }
  },
  computed: {
    ...mapGetters({currentApartment:"currentApartment", monthlyData: "currentApartmentMonthlyData", currentApartmentReadingTotals: "currentApartmentReadingTotals", isGranted: "isGranted"})
  },
  methods: {
    ...mapActions(["fetchReadings", "fetchApartment", "clearReadings"]),
    isSelected(btn) {
      if (btn === this.viewMode) {
        return "primary";
      }
      return "";
    },
    select(btn) {
      this.viewMode = btn;
    },
    async compare() {
      if (!this.compareMode) {
        const y = this.selectedYear - 1;
        if (!this.monthlyData[y]) {
          await this.fetchReadings({"year": y});
        }
        this.updater++;
        await this.$nextTick();
        this.createDatasets();
        this.compareMode = true;

      } else {
        this.compareMode = false;
        await this.yearChanged()

      }
    },
    async yearChanged() {
      const y = this.selectedYear;
      if (!this.monthlyData[y]) {
        await this.fetchReadings({"year": y});
      }
      if(this.compareMode && !this.monthlyData[y-1]){
        await this.fetchReadings({"year": y-1});
      }
      this.updater++;
      await this.$nextTick();
      this.createDatasets();
    },
    async fetchInitialData() {
      let apartmentId = this.$route.params.id;
      if(!apartmentId) return;
      if (!this.currentApartment || this.currentApartment.id !== apartmentId) {
        await this.fetchApartment(apartmentId);
      }
      this.years = [];
      this.selectedYear = new Date().getFullYear();
      for(let i =0; i<5; i++){
        this.years.push(this.selectedYear - i);
      }
      await this.clearReadings();
      await this.yearChanged();
    },
    getLabel(meter){
      const arr = [];
      if (meter.description){
        arr.push(meter.description);
      }
      arr.push(meter.serialNumber);
      if (meter.kMeter && meter.kMeter!==1){
        arr.push("k: "+meter.kMeter);
      }

      return arr.join(" - ");
    },
    createDatasets() {
      this.datasets = {};
      const readings = this.currentApartmentReadingTotals[this.selectedYear];
      this.datasets["Totale"] = this.createDataset(readings.monthlyReadings);
      console.log(this.monthlyData)
      for (let m in this.monthlyData[this.selectedYear]) {

        const meter = this.monthlyData[this.selectedYear][m];
        this.datasets[this.getLabel(meter)] = this.createDataset(meter.monthlyReadings)
      }

      if (this.currentApartmentReadingTotals[this.selectedYear - 1]) {
        const readings2 = this.currentApartmentReadingTotals[this.selectedYear - 1];
        this.datasets2["Totale"] = this.createDataset(readings2.monthlyReadings);

        for (let m in this.monthlyData[this.selectedYear - 1]) {
          const meter = this.monthlyData[this.selectedYear - 1][m];
          this.datasets2[this.getLabel(meter)] = this.createDataset(meter.monthlyReadings)
        }
      }
      this.$refs.readingsChart.renderCharts(this.datasets, this.datasets2);
    },
    createDataset(map) {
      const list = Array.from(map.values());
      return {
        labels: list.map(r => r.date.format("MM/YY")),
        readings: list.map(r => r.reading),
        consumptions: list.map(r => r.consumption),
      }
    },
    createDatasetOld(values) {
      let monthlyData = {};
      for (let r of values) {
        let date = new Date(r.dateTime);
        let month = this.monthList[date.getMonth()];
        monthlyData[month.toString()] = r.value;
      }
      let readings = Object.values(monthlyData);
      let months = Object.keys(monthlyData);
      let consumptions = [];
      consumptions[0] = 0;
      if(readings.length === 1){
        readings.unshift(0);
        months.push(months[0]);
      }
      for (let i = 1; i < readings.length; i++) {
        consumptions[i] = readings[i] - readings[i - 1];
      }
      return {labels: months, readings: readings, consumptions: consumptions}
    },
    openCreateReadingDialog(){
      this.fetchApartment(this.$route.params.id)
      this.$refs.createReadingDialog.show();
    }

  },
  watch: {
    async $route(to, from) {
      if (to !== from) {
        await this.fetchInitialData();
      }
      //this.updateView = !this.updateView
    }
  },
  async mounted() {
    await this.fetchInitialData();
  }
}
</script>

<style scoped>
.ml-10{
  margin-left: 10px;
}
</style>
