<template>
  <el-tabs type="border-card">
    <el-tab-pane label="Letture" :key="compareMode">
      <el-row v-for="meter in meters" :key="meter">
        <chart-container
            :ref="'readings'+meter.replace(/ /g,'')"
            :meter="meter"
            type="Letture"
            :name="'readings'+meter.replace(/ /g,'')"
            :labels="datasets[meter].labels"
            :data="datasets[meter].readings"
            :compare="compareReadings(meter)"
        ></chart-container>
      </el-row>
    </el-tab-pane>
    <el-tab-pane label="Consumi" :key="!compareMode">
      <el-row v-for="meter in meters" :key="meter">
        <chart-container
            :ref="'readings'+meter.replace(/ /g,'')"
            :meter="meter"
            type="Consumi"
            :name="'readings'+meter.replace(/ /g,'')"
            :labels="datasets[meter].labels"
            :data="datasets[meter].consumptions"
            :compare="compareConsumptions(meter)"
        ></chart-container>
      </el-row>
    </el-tab-pane>
  </el-tabs>

</template>

<script>
import ChartContainer from "./ChartContainer";

export default {
  name: "ApartmentReadingsChart",
  components: {ChartContainer},
  props: ["compareMode"],
  data(){
    return {
      meters: [],
      datasets: {},
      datasets2: {},
      groups: [],
      datasetsGroups: {},
      charts: {},
    }
  },
  computed: {
    // ...mapGetters(["currentApartmentReadingList", "currentApartmentReadingTotals"])
  },
  methods: {
    renderCharts(datasets, datasets2){
      this.datasets = datasets;
      this.datasets2 = datasets2;
      if(this.datasets){
        this.meters = Object.keys(datasets);
      }
    },
    compareReadings(m){
      if(this.compareMode && this.datasets2[m]){
        return this.datasets2[m].readings;
      }
    },
    compareConsumptions(m){
      if(this.compareMode && this.datasets2[m]){
        return this.datasets2[m].consumptions;
      }
    }

  }
}
</script>

<style scoped>

</style>
