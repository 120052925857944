<template>
  <el-col :span="24" class="chart-container">
    <h3>{{ type }} {{ meter }}</h3>
    <canvas :ref="name" width="100%" height="250"></canvas>
  </el-col>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "Chart.vue",
  props: ["type", "meter", "name", "labels", "data", "compare"],
  data() {
    return {
      chartObj: null,
    }
  },
  methods: {
    render() {
      let readingsData = {
        labels: this.labels,
        datasets: [{
          label: "Letture " + this.meter,
          data: this.data,
          fill: false,
          borderColor: "#FF0000",
          tension: 0
        }]
      };
      if(this.compare){
        readingsData.datasets.push({
          label: "Letture " + this.meter + " anno precedente",
          data: this.compare,
          fill: false,
          borderColor: "#0000FF",
          tension: 0
        });
      }
      let ctx = this.$refs[this.name];
      this.chartObj = new Chart(ctx, {
        type: 'line',
        data: readingsData,
      });
    }
  },
  mounted() {
    this.render();
  },
}
</script>

<style scoped>
  .chart-container{
    min-height: 300px;
  }

</style>
