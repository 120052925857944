<template>
  <el-tabs type="border-card">


    <el-tab-pane v-for="meter in currentApartmentMonthlyData[selectedYear]"
                 :label= "getMeterLabel(meter)"
                 :key="meter.serialNumber">
      <el-table
          :data="getData(meter)"
          stripe
          style="width: 100%">
        <el-table-column
            prop="month"
            label="Mese"
        >
          <template #default="scope">
            {{ scope.row.date.format("MM/YY") }}
          </template>
        </el-table-column>
        <el-table-column
            prop="reading"
            label="Lettura"
        >
          <template #default="scope">
            <el-tooltip
                v-if="scope.row['auto']"
                class="item"
                effect="dark"
                content="Lettura non pervenuta"
            >
              <div style="width: fit-content">{{scope.row.reading}} *</div>
            </el-tooltip>
            <span v-else>
              {{scope.row.reading}}
            </span>
          </template>
        </el-table-column>
        <el-table-column
            label="Lettura Annno Prec."
            v-if="compareMode"
        >
          <template #default="scope">
            {{ getLetturaPrecedente(scope, meter) }}
          </template>
        </el-table-column>
        <el-table-column
            prop="consumption"
            label="Consumo Mensile">
        </el-table-column>
        <el-table-column
            prop="consumption"
            label="Consumo anno Prec."
            v-if="compareMode"
        >
          <template #default="scope">
            {{ getConsumoPrecedente(scope, meter) }}
          </template>
        </el-table-column>
      </el-table>
    </el-tab-pane>
    <el-tab-pane label= "Totali">
      <el-table
          :data="getTotals()"
          stripe
          style="width: 100%">
        <el-table-column
            prop="month"
            label="Mese"
        >
          <template #default="scope">
            {{ scope.row.date.format("MM/YY") }}
          </template>
        </el-table-column>
        <el-table-column
            prop="reading"
            label="Lettura"
        >
        </el-table-column>
        <el-table-column
            label="Lettura Annno Prec."
            v-if="compareMode"
        >
          <template #default="scope">
            {{ getLetturaTotalePrecedente(scope) }}
          </template>
        </el-table-column>
        <el-table-column
            prop="consumption"
            label="Consumo Mensile">
        </el-table-column>
        <el-table-column
            prop="consumption"
            label="Consumo anno Prec."
            v-if="compareMode"
        >
          <template #default="scope">
            {{ getConsumoTotalePrecedente(scope) }}
          </template>
        </el-table-column>
      </el-table>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import {mapGetters} from "vuex";
import DateMixin from "../../mixins/DateMixin";

export default {
  name: "ApartmentReadingsList",
  props: {
    selectedYear: Number,
    compareMode: Boolean,
  },
  mixins: [DateMixin],
  computed: {
    ...mapGetters({currentApartmentMonthlyData: "currentApartmentMonthlyData", currentApartmentReadingTotals: "currentApartmentReadingTotals"})
  },

  methods: {
    getData(meter) {
      return Array.from(meter.monthlyReadings.values());
    },
    getTotals(){
      if(this.currentApartmentReadingTotals[this.selectedYear]) {
        return Array.from(this.currentApartmentReadingTotals[this.selectedYear].monthlyReadings.values());
      }
      return []
    },
    getLetturaPrecedente(scope, meter) {
      try {
        const m = Object.values(this.currentApartmentMonthlyData[this.selectedYear - 1]).find(e => e.serialNumber === meter.serialNumber);
        return Array.from(m.monthlyReadings.values())[scope.$index].reading;
      } catch {
        return ""
      }
    },
    getConsumoPrecedente(scope, meter) {
      try {
        const m = Object.values(this.currentApartmentMonthlyData[this.selectedYear - 1]).find(e => e.serialNumber === meter.serialNumber);
        return Array.from(m.monthlyReadings.values())[scope.$index].consumption;
      } catch {
        return ""
      }
    },
    getLetturaTotalePrecedente(scope) {
      try {
        return Array.from(this.currentApartmentReadingTotals[this.selectedYear - 1].monthlyReadings.values())[scope.$index].reading;
      } catch {
        return ""
      }
    },
    getConsumoTotalePrecedente(scope) {
      try {
        return Array.from(this.currentApartmentReadingTotals[this.selectedYear - 1].monthlyReadings.values())[scope.$index].consumption;
      } catch {
        return ""
      }
    },
    getMeterLabel(meter){
      if(meter.kMeter !== 1){
        return meter.description + ' - ' + meter.serialNumber + ' - k: ' + meter.kMeter;
      }
      return meter.description + ' - ' + meter.serialNumber;
    },
    printScope(scope, meter){
      console.log(scope)
      console.log(meter)
      return "gigio"
    }
  }
}
</script>

<style scoped>

</style>
